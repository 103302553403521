import { render, staticRenderFns } from "./SelectPermissionType.vue?vue&type=template&id=580602a8&scoped=true"
import script from "./SelectPermissionType.vue?vue&type=script&lang=js"
export * from "./SelectPermissionType.vue?vue&type=script&lang=js"
import style0 from "./SelectPermissionType.vue?vue&type=style&index=0&id=580602a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580602a8",
  null
  
)

export default component.exports